var exports = {};
// btoa polyfill for IE<10 courtesy https://github.com/davidchambers/Base64.js
var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

function E() {
  this.message = "String contains an invalid character";
}

E.prototype = new Error();
E.prototype.code = 5;
E.prototype.name = "InvalidCharacterError";

function btoa(input) {
  var str = String(input);
  var output = "";

  for ( // initialize result and counter
  var block, charCode, idx = 0, map = chars; // if the next str index does not exist:
  //   change the mapping table to "="
  //   check if d has no fractional digits
  str.charAt(idx | 0) || (map = "=", idx % 1); // "8 - idx % 1 * 8" generates the sequence 2, 4, 6, 8
  output += map.charAt(63 & block >> 8 - idx % 1 * 8)) {
    charCode = str.charCodeAt(idx += 3 / 4);

    if (charCode > 255) {
      throw new E();
    }

    block = block << 8 | charCode;
  }

  return output;
}

exports = btoa;
export default exports;